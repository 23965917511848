import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Rocket from '../images/new/banners/estrutura-3.svg'
import Banner from '../images/new/banners/missao-visao-valores.png'

import { 
  section,
  HeaderBanner,
  container,
  content,
  bottomImg
} from '../components/styles/Base.styles'

const MissaoVisaoValores = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Missão, Visão e Valores | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section, content]}>
      <div css={container}>
        <h1>Missão, Visão e Valores</h1>
        <p>Nosso compromisso é formar cidadãos éticos, que saibam quem são, que compreendam seus dons, lidem com a vida de modo alegre e humilde e sintam um profundo desejo de transformar o seu entorno. Isso faz parte do DNA da Kingdom.</p>
        <h2 style={{ marginBottom: 24 }}>Missão</h2>
        <p>Revolucionar o processo de desenvolvimento, por meio de uma experiência significativa e única.</p>
        <h2 style={{ marginBottom: 24 }}>Visão</h2>
        <p>Crescimento da marca em 10 anos, obtendo novas franquias pelo Brasil.</p>
        <h2 style={{ marginBottom: 24 }}>Valores</h2>
        <p>Responsabilidade; Humanismo; Proatividade; Inovação; Criatividade.</p>
      </div>
    </div>
    <div css={bottomImg}>
      <img src={Rocket} alt='' style={{ width: '15%' }} />
    </div>
    <Footer />
  </Fragment>
)

export default MissaoVisaoValores
